import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './FormStepper.module.scss';

import Button from '../Button/Button';
import Spinner from 'components/Spinner/Spinner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Icon from 'components/Icon/Icon';

export interface FormStepperStep {
  label: string;
  step: number;
  hasError?: boolean;
  disabled?: boolean;
}

export interface FormStepperBreadcrumb {
  to?: string;
  name: string;
}

interface Props {
  activeStep: number;
  steps: FormStepperStep[];
  breadcrumbs: FormStepperBreadcrumb[];
  loading?: boolean;
  isNew?: boolean;
  isClickDisabled?: boolean;
  isSubmitDisabled?: boolean;
  children?: JSX.Element;
  onStepBackClick?: () => void;
  onStepNextClick?: () => void;
  onStepChange?: (stepIndex: number) => void;
  onSubmit?: () => Promise<void> | void;
  onDelete?: () => Promise<void> | void;
}

class FormStepper extends Component<Props> {
  render() {
    const {
      activeStep,
      steps,
      breadcrumbs,
      loading,
      isNew,
      isClickDisabled,
      isSubmitDisabled,
      children,
      onStepBackClick,
      onStepNextClick,
      onSubmit,
      onDelete,
    } = this.props;

    return (
      <main className={style.main}>
        <header className={style.header}>
          <Breadcrumbs
            links={breadcrumbs}
            className="admin-header--breadcrumbs"
          />
        </header>

        {loading ? (
          <Spinner contain />
        ) : (
          <div className={style.form}>
            <div className={style.body}>
              <div className={style.steps__wrapper}>
                <div className={style.steps__list}>
                  {steps.map(step => (
                    <div
                      className={classNames(
                        style.step__item,
                        step.step < activeStep && style.step__passed,
                        step.step === activeStep && style.step__active,
                        step.step > activeStep && style.step__unpassed,
                        step.hasError && style.error,
                      )}
                      key={step.step}
                    >
                      <div className={style.step__border}>
                        <div
                          className={classNames(
                            style.border__item,
                            step.step <= activeStep
                              ? style.passed__border
                              : style.unpassed__border,
                            step.step === 0 && style.hidden__border,
                          )}
                        />
                        <div
                          className={classNames(
                            style.border__item,
                            step.step < activeStep
                              ? style.passed__border
                              : style.unpassed__border,
                            step.step === steps.length - 1 &&
                              style.hidden__border,
                          )}
                        />
                      </div>

                      <div
                        className={classNames(
                          style.step__item_label,
                          (isClickDisabled || step.disabled) &&
                            style.click__disabled,
                        )}
                        onClick={() => this._handleStepChange?.(step)}
                      >
                        <span className={style.step__dot} />
                        <span className={style.step__label}>
                          <FormattedMessage id={step.label} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={style.form__container}>{children}</div>
            </div>

            <div className={style.controls}>
              <div className={style.controls__left}>
                {!isNew && !!onDelete && (
                  <Button
                    className={style.btn}
                    styleType="warning"
                    onClick={onDelete}
                  >
                    <FormattedMessage id="button.delete" />
                  </Button>
                )}
              </div>
              <div className={style.controls__right}>
                <Button
                  className={style.btn}
                  styleType="ghost"
                  onClick={onStepBackClick}
                >
                  <FormattedMessage id="button.back" />
                </Button>

                {this._isLastStep ? (
                  <Button
                    disabled={isClickDisabled || isSubmitDisabled}
                    className={classNames(style.btn, style.btn__next)}
                    styleType="primary"
                    onClick={onSubmit}
                  >
                    <FormattedMessage
                      id={isNew ? 'button.create' : 'button.save'}
                    />
                  </Button>
                ) : (
                  <Button
                    disabled={this._isNextButtonDisabled}
                    className={classNames(style.btn, style.btn__next)}
                    styleType="primary"
                    onClick={onStepNextClick}
                  >
                    <FormattedMessage id={'button.next'} />
                    <Icon className={style.btn__next_arrow} type="arrowNext" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }

  private get _isNextButtonDisabled() {
    const { activeStep, steps, isClickDisabled } = this.props;
    if (isClickDisabled) return true;

    if (this._isLastStep) return false;

    const nextStep = steps[activeStep + 1];
    return !!nextStep.disabled;
  }

  private get _isLastStep() {
    const { activeStep, steps } = this.props;
    return activeStep === steps[steps.length - 1].step;
  }

  private _handleStepChange = (step: FormStepperStep) => {
    if (step.disabled) return;

    this.props.onStepChange?.(step.step);
  };
}

export default FormStepper;
