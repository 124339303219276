import React, { Component } from 'react';
import style from './Subsidies.module.scss';

import { ROUTES } from 'routes/routes';
import NavigationTabs, {
  NavigationTab,
} from 'components/NavigationTabs/NavigationTabs';
import { Route, Switch } from 'react-router';
import StaffGroups from './StaffGroups/StaffGroups';
import CampaignPage from './Campaign/CampaignPage';
import SubsidiesList from './SubsidiesList/SubsidiesList';
import { observer } from 'mobx-react';
import EmployeeDiscounts from './EmployeeDiscounts/EmployeeDiscounts';

@observer
class SubsidiesPage extends Component {
  tabs: NavigationTab[] = [];

  render() {
    this.tabs = [
      { to: ROUTES.admin.discounts, title: 'title.discountsList' },
      { to: ROUTES.admin.staffGroups, title: 'title.userGroups' },
      { to: ROUTES.admin.campaign, title: 'title.campaigns' },
      {
        to: ROUTES.admin.employeeDiscounts,
        title: 'title.employeeDiscounts',
        description: 'title.employeeDiscounts.description',
      },
    ];

    return (
      <main className={style.main}>
        <NavigationTabs tabs={this.tabs} />

        <Switch>
          <Route
            path={ROUTES.admin.discounts}
            component={SubsidiesList}
            exact
          />
          <Route
            path={ROUTES.admin.staffGroups}
            component={StaffGroups}
            exact
          />
          <Route path={ROUTES.admin.campaign} component={CampaignPage} exact />
          <Route
            path={ROUTES.admin.employeeDiscounts}
            component={EmployeeDiscounts}
            exact
          />
        </Switch>
      </main>
    );
  }
}

export default SubsidiesPage;
