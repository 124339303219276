import { observable } from 'mobx';

import {
  BaseFormModel,
  hasMaxLength,
  isNumberMore,
  isRequired,
  isSameAs,
} from 'stores/BaseForm';
import { EmployeeDiscountType } from 'http/Api/Subsidy/EmployeeDiscounts/types';
import { hasMinLength } from 'stores/BaseForm/validators/hasMinLength';
import { hasOnlySymbols } from 'stores/BaseForm/validators/hasOnlySymbols';

// from 1 to 9
const availablePinSymbols = [...Array(9).keys()].map(i => (i + 1).toString());

class EmployeeDiscountItemForm extends BaseFormModel {
  @observable
  @isRequired()
  @hasMaxLength(100)
  title = '';

  @observable
  @isRequired()
  discountType = EmployeeDiscountType.PERCENT;

  @observable
  @isRequired()
  @isNumberMore(0)
  discountValue = 0;

  @observable
  @isRequired()
  @hasMinLength(4)
  @hasMaxLength(4)
  @hasOnlySymbols(availablePinSymbols)
  pin = '';

  @observable
  @isRequired()
  @isSameAs('pin')
  pinCheck = '';
}

export default EmployeeDiscountItemForm;
