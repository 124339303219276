import React, { FC } from 'react';
import classNames from 'classnames';
import { Omit } from 'helpers/types';

import style from '../Fields.module.scss';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  invalid?: boolean;
  onChange?: (v: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  postfix?: string;
}

const NumberInput: FC<Props> = ({
  value,
  className,
  min = 0,
  max,
  ...props
}: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = Number(event.target.value);
    const isValueInvalid =
      (min !== undefined && targetValue < Number(min)) ||
      (max !== undefined && targetValue > Number(max));

    if (props.onChange && !isValueInvalid) {
      props.onChange(Number(targetValue), event);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  const { invalid, postfix, ...rest } = props;

  return (
    <div
      className={classNames(style.number__wrapper, className)}
      data-postfix={postfix}
    >
      <input
        {...rest}
        className={classNames(style.field, {
          [style.field__postfix]: postfix,
          [style.field__invalid]: invalid,
        })}
        min={min}
        max={max}
        value={String(value)}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type="number"
      />
    </div>
  );
};

export default NumberInput;
