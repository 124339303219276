import { action, computed, observable } from 'mobx';

import EmployeeDiscountItemForm from 'stores/Forms/EmployeeDiscountItemForm';
import {
  CreateUpdateEmployeeDiscountItemDto,
  EmployeeDiscountDto,
} from 'http/Api/Subsidy/EmployeeDiscounts/types';
import { PromiseService } from 'services/PromiseService';
import AppRouter from 'stores/AppRouter';
import httpFacade from 'http/httpFacade';
import { EmployeeDiscountItemSteps } from './types';

class EmployeeDiscountItemStore {
  public id: string;

  @observable public activeStep = EmployeeDiscountItemSteps.Title;
  @observable public discount: EmployeeDiscountDto | null = null;
  @observable public form = new EmployeeDiscountItemForm();
  @observable public isInit = false;

  @observable private _promiseService = new PromiseService();

  constructor(id?: string) {
    this.id = id || '';
  }

  @action
  public init = async () => {
    try {
      await this._loadItem();
    } finally {
      this.isInit = true;
    }
  };

  @action
  public handleSubmit = async () => {
    if (!this.form.validate()) return;

    const { title, discountType, discountValue, pin } = this.form;

    const data: CreateUpdateEmployeeDiscountItemDto = {
      title,
      type: discountType,
      value: discountValue,
      pin,
    };

    await this._promiseService.add(() =>
      httpFacade.employeeDiscounts.createItem(data),
    );

    AppRouter.goBack();
  };

  public handleStepBack = () => {
    if (this.activeStep === EmployeeDiscountItemSteps.Title) {
      AppRouter.goBack();
      return;
    }

    this._setStep(this.activeStep - 1);
  };

  public handleStepNext = () => {
    this._setStep(this.activeStep + 1);
  };

  public handleStepChange = (newStep: EmployeeDiscountItemSteps) => {
    this._setStep(newStep);
  };

  @action
  private _loadItem = async () => {
    if (!this.id) return;

    try {
      const { data } = await this._promiseService.add(this._fetchItem);

      if (data) {
        this.discount = data;

        const { title, type, value, pin } = data;

        this.form.title = title;
        this.form.discountType = type;
        this.form.discountValue = value;
        this.form.pin = pin.toString();
      }
    } catch (error) {
      AppRouter.goBack();
    }
  };

  @action
  private _setStep = (step: EmployeeDiscountItemSteps) => {
    this.activeStep = step;
  };

  private _fetchItem = () => {
    return httpFacade.employeeDiscounts.fetchItemById(this.id);
  };

  @computed
  public get loading() {
    return this._promiseService.isPending;
  }
}

export default EmployeeDiscountItemStore;
