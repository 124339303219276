import React, { useState } from 'react';
import { Column } from 'react-table';

import { withArrow } from 'components/Table/helpers';

import style from './EmployeeDiscounts.module.scss';
import RootStore from 'stores/RootStore';
import {
  EmployeeDiscountDto,
  EmployeeDiscountType,
} from 'http/Api/Subsidy/EmployeeDiscounts/types';
import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';

interface ConfigOptions {
  onActiveStateUpdate: (id: string, active: boolean) => void;
}

export const config = ({ onActiveStateUpdate }: ConfigOptions): Column[] => [
  {
    Header: withArrow('title.employeeDiscounts.title'),
    accessor: 'title',
    resizable: true,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.employeeDiscounts.model'),
    accessor: 'value',
    resizable: true,
    Cell: row => {
      const type = (row.original as EmployeeDiscountDto).type;
      const messageId =
        type === EmployeeDiscountType.AMOUNT
          ? 'title.employeeDiscounts.fixed.model'
          : 'title.employeeDiscounts.percent.model';

      const value =
        type === EmployeeDiscountType.AMOUNT
          ? (row.value / 100).toFixed(2)
          : row.value;

      return (
        <span className={style.cell__text}>
          {RootStore.localization.formatMessage(messageId, {
            value,
          })}
        </span>
      );
    },
  },
  {
    Header: withArrow('title.employeeDiscounts.pin'),
    accessor: 'pin',
    width: 150,
    Cell: row => {
      const [isVisible, setIsVisible] = useState(false);

      return (
        <span
          className={style.cell__text}
          onClick={() => setIsVisible(visible => !visible)}
        >
          {isVisible ? row.value : row.value.toString().replaceAll(/./g, '*')}
        </span>
      );
    },
  },
  {
    Header: withArrow('title.employeeDiscounts.activeState'),
    accessor: 'active',
    resizable: false,
    width: 120,
    Cell: row => {
      const id = (row.original as EmployeeDiscountDto).id;

      return (
        <SwitchButton
          onChange={isChecked => onActiveStateUpdate(id, isChecked)}
          checked={row.value}
          className={style.control__switcher}
        />
      );
    },
  },
];
