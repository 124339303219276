import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import EmployeeDiscountItemForm from 'stores/Forms/EmployeeDiscountItemForm';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import { FormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';

import style from './EmployeeDiscountItemSteps.module.scss';

interface Props {
  form: EmployeeDiscountItemForm;
}

@observer
class EmployeeDiscountItemStepTitle extends Component<Props> {
  render() {
    const { form } = this.props;

    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.employeeDiscounts.steps.title"
          tooltip="title.employeeDiscounts.steps.title.description"
        />

        <div className={style.form_group}>
          <div className={style.form_label}>
            <FormattedMessage id="title.employeeDiscounts.steps.title" />
          </div>
          <div className={style.form_description}>
            <FormattedMessage id="title.employeeDiscounts.steps.title.description" />
          </div>
          <FormControl
            form={form}
            name="title"
            className={style.form_control}
            render={props => <TextField {...props} />}
          />
        </div>
      </div>
    );
  }
}

export default EmployeeDiscountItemStepTitle;
