import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import EmployeeDiscountItemForm from 'stores/Forms/EmployeeDiscountItemForm';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import { FormControl } from 'components/Form/FormControl/FormControl';
import PasswordField from 'components/Form/Fields/Password/PasswordField';

import style from './EmployeeDiscountItemSteps.module.scss';

interface Props {
  form: EmployeeDiscountItemForm;
}

@observer
class EmployeeDiscountItemStepPin extends Component<Props> {
  render() {
    const { form } = this.props;

    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.employeeDiscounts.steps.pin"
          tooltip="title.employeeDiscounts.steps.pin.description"
        />

        <div className={style.form_group}>
          <div className={style.form_description}>
            <FormattedMessage id="title.employeeDiscounts.steps.pin.pin.description" />
          </div>
          <FormControl
            form={form}
            name="pin"
            className={style.form_control}
            render={props => <PasswordField {...props} showVisibilityButton />}
          />
        </div>
        <div className={style.form_group}>
          <div className={style.form_description}>
            <FormattedMessage id="title.employeeDiscounts.steps.pin.pinCheck.description" />
          </div>
          <FormControl
            form={form}
            name="pinCheck"
            className={style.form_control}
            render={props => <PasswordField {...props} showVisibilityButton />}
          />
        </div>
      </div>
    );
  }
}

export default EmployeeDiscountItemStepPin;
