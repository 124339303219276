import { MenuItemLabel } from 'http/Api/MenuItem/types';

export interface DishGroupManagement {
  id: string;
  tag: string;
  title: string;
  parentId: string;
  itemNumber: string;
  itemsCount: number;
  subsidizedItemsCount: number;
  forDepositItems: boolean;
  standardMenuItems: boolean;
  notVisibleKioskSelfCheckout: boolean;
}

export interface IMenuCategory {
  id: string;
  title: string;
  items: MenuItem[];
}

export interface IMenuLine {
  id: string;
  title: string;
  deleted: boolean;
  onlineOrder: boolean;
  prioritized: boolean;
  orderTimeFrom?: string; // HH:mm:ss
  orderTimeTo?: string; // HH:mm:ss
  minTimeBeforeOrder?: number; // minutes
  code: string;
}

export interface MenuLineCreateUpdateDto {
  onlineOrder: boolean;
  orderTimeFrom?: string; // HH:mm:ss
  orderTimeTo?: string; // HH:mm:ss
  minTimeBeforeOrder?: number; // minutes
  prioritized: boolean;
  title: string;
  code: string;
}

export interface MenuManagementDate {
  date: string;
  items: MenuItem[];
}

export enum Periods {
  week = 'week',
}

export interface MenuItem extends Omit<DishItem, 'dishGroup'> {
  id: string;
  tag: string;
  title: string;
  type: string;
  itemNumber: string;
  dishGroup: DishGroupManagement;
  amount?: number;
  visibleOnline: boolean;
  notVisibleKioskSelfCheckout: boolean;
  price: number;
  requiresConsumptionCondition: boolean;
  upsellingLists?: UpsellingList[];
  initStock: number;
  fixedAmount: boolean;
  currentValue: number;
  additionalValue: number;
}

export interface UpsellingList {
  order: number;
  title: string;
  enabled: boolean;
  menuItemsIds: string[] | null;
  dishGroupsIds: string[] | null;
}

export interface MenuItems {
  content: MenuItem[];
  totalPages: number;
  number: number;
  size: number;
  totalElements: number;
}

export interface MenuItemImage {
  menuItemId: string;
  image: string;
}

export interface DishItem {
  id?: string;
  tag?: string;
  description?: string;
  title: string;
  dishGroup: string;
  price: number;
  vatRates: number[];
  subsidized: boolean;
  quickAccess: boolean;
  notVisibleKioskSelfCheckout: boolean;
  ingredients: {
    kcal?: number;
    protein?: number;
    fat?: number;
    sugar?: number;
    carbs?: number;
  };
  co2?: number;
  healthAspects?: string[];
  specification: string[];
  allergens: AllergenType[];
  specifications?: string[];
  depositItemId?: string;
  labels: MenuItemLabel[];
  upsellingLists?: UpsellingList[];
}

export interface DishAspect {
  id: string;
  title: string;
  icon?: string;
}

export interface AllergenType {
  id: string;
  title: string;
  icon?: string;
}

export enum SpecificationType {
  HEALTH_ASPECTS = 'HEALTH_ASPECTS',
  ADDITIVES = 'ADDITIVES',
  QUALITY_STANDARDS = 'QUALITY_STANDARDS',
  ALLERGENS = 'ALLERGENS',
}

export interface Specification {
  id: string;
  title: string;
  name: SpecificationType;
  values: Array<{ id: string; title: string }>;
}

export interface MenuLineManagement {
  line: string;
  lineTitle: string;
  days: MenuManagementDate[];
}

export interface CreateMenuLineDto {
  line: string;
  days: Array<{
    date: string;
    items: Array<{
      item: string;
      initStock: number;
      currentAmount: number;
      additionalAmount: number;
      fixedAmount: boolean;
    }>;
  }>;
}

export interface ExtendedAvailable {
  enabled: boolean;
}

export interface Catering {
  name: string;
  vatin: string;
  taxNumber: string;
  address: string;
  postcode: number;
  city: string;
  country: string;
  logo: string;
  id: string;
  accountId: string;
  cateringProviderInfo?: string;
  techProviderInfo?: string;
}
