import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function hasMaxDecimalLength(
  length: number,
  message = 'VM_HAS_MAX_DECIMAL_LENGTH',
) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        let isValid = true;

        if (!Number.isNaN(+value) && value.toString().includes('.')) {
          const parts = value.toString().split('.');
          isValid = parts[1].length <= length;
        }

        return isValid
          ? undefined
          : {
              message,
              data: {
                name,
                value,
                length,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
