import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import EmployeeDiscountItemForm from 'stores/Forms/EmployeeDiscountItemForm';
import { EmployeeDiscountType } from 'http/Api/Subsidy/EmployeeDiscounts/types';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import { FormControl } from 'components/Form/FormControl/FormControl';
import RadioField from 'components/Form/Fields/Radio/RadioField';

import style from './EmployeeDiscountItemSteps.module.scss';
import CurrencyInput from 'components/Form/Fields/Number/CurrencyInput';
import { EUR } from 'helpers/currency';
import NumberInput from 'components/Form/Fields/Number/NumberInput';

interface Props {
  form: EmployeeDiscountItemForm;
}

@observer
class EmployeeDiscountItemStepType extends Component<Props> {
  render() {
    const { form } = this.props;

    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.employeeDiscounts.steps.type"
          // tooltip="title.employeeDiscounts.steps.type.description"
        />

        <div className={style.form_group}>
          <ul>
            {Object.values(EmployeeDiscountType).map(type => {
              const disabled = type === EmployeeDiscountType.AMOUNT;

              return (
                <li
                  key={type}
                  className={classNames(
                    style.discount_type,
                    disabled && style.discount_type_disabled,
                  )}
                >
                  <FormControl
                    name="discountType"
                    form={form}
                    render={fieldProps => (
                      <RadioField
                        {...fieldProps}
                        onChange={val => {
                          this._handleDiscountTypeChange(
                            val as EmployeeDiscountType,
                          );
                          fieldProps.onChange(val);
                        }}
                        title={`title.employeeDiscounts.steps.type.${type}`}
                        name={type}
                        value={type}
                        checked={form.discountType === type}
                        radioButtonStyleType={'circle'}
                        className={style.form_label}
                        disabled={disabled}
                      />
                    )}
                  />
                  <div
                    className={classNames(
                      style.form_description,
                      style.radio_description,
                    )}
                  >
                    <FormattedMessage
                      id={`title.employeeDiscounts.steps.type.${type}.description`}
                    />
                  </div>
                </li>
              );
            })}
          </ul>

          <div
            className={classNames(style.input_with_label, style.input_amount)}
          >
            <div className={style.input_label}>
              <FormattedMessage id="title.employeeDiscounts.steps.type.value.label" />
            </div>

            <FormControl
              name="discountValue"
              form={form}
              className={style.number_input}
              render={fieldProps =>
                form.discountType === EmployeeDiscountType.AMOUNT ? (
                  <CurrencyInput
                    {...fieldProps}
                    postfix={form.discountType ? EUR : ''}
                    maskOptions={{ integerLimit: undefined }}
                    disabled={!form.discountType}
                    className={style.field_amount}
                  />
                ) : (
                  <NumberInput
                    {...fieldProps}
                    postfix={form.discountType ? '%' : ''}
                    max={100}
                    placeholder="0"
                    disabled={!form.discountType}
                    className={style.field_amount}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }

  private _handleDiscountTypeChange = (type?: EmployeeDiscountType) => {
    const { form } = this.props;

    if (!type) {
      return;
    }

    let value = form.discountValue;

    if (type === EmployeeDiscountType.PERCENT) {
      value = value / 100;
      value = value > 100 ? 100 : value;
    } else if (type === EmployeeDiscountType.AMOUNT) {
      value = value * 100;
    } else {
      value = 0;
    }
    form.setValue('discountValue', value);
  };
}

export default EmployeeDiscountItemStepType;
