import { action, computed, observable } from 'mobx';

import { PromiseService } from 'services/PromiseService';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

import { EmployeeDiscountDto } from 'http/Api/Subsidy/EmployeeDiscounts/types';
import AppRouter from 'stores/AppRouter';
import { ROUTES } from 'routes/routes';

class EmployeeDiscountsStore {
  @observable private _data: EmployeeDiscountDto[] = [];
  @observable private _searchValue = '';
  @observable private _promiseService = new PromiseService();

  @action.bound
  async fetchEmployeeDiscounts() {
    try {
      const { data } = await this._promiseService.add(
        httpFacade.employeeDiscounts.fetchDiscounts,
      );

      this._data = data;
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  createDiscount() {
    AppRouter.push(ROUTES.admin.employeeDiscountItemNew);
  }

  @action.bound
  async updateActiveState(id: string, active: boolean) {
    try {
      const { data } = await httpFacade.employeeDiscounts.updateActiveState(
        id,
        active,
      );

      this._data = this._data.map(discount => {
        if (discount.id === id) {
          return data;
        }

        return discount;
      });
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async setSearch(search: string) {
    this._searchValue = search;
  }

  @computed
  public get searchValue() {
    return this._searchValue;
  }

  @computed
  public get data() {
    const value = this.searchValue.toLocaleLowerCase();

    return this.searchValue
      ? this._data.filter(item =>
          item.title?.toLocaleLowerCase().includes(value),
        )
      : this._data;
  }

  @computed
  public get loading() {
    return this._promiseService.isPending;
  }
}

export default EmployeeDiscountsStore;
