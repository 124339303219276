import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isNumber(onlyInteger?: boolean, msg?: string) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];

        let isValid = !value || !isNaN(Number(value));
        let errorMessage = 'VM_IS_NUMBER';
        if (
          onlyInteger &&
          isValid &&
          value !== undefined &&
          !Number.isInteger(Number(value))
        ) {
          isValid = false;
          errorMessage = 'VM_IS_INTEGER';
        }
        return isValid
          ? undefined
          : {
              message: msg || errorMessage,
              data: {
                name,
                value,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
