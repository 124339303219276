export const ROUTES = {
  root: '/',

  registration: '/registration/:campaignId',
  rfid: '/rfid',
  addUser: '/add-user',

  login: '/login',
  recover: '/recover',
  employees: '/employees',

  user: {
    profile: '/profile',
    onlineOrder: '/profile/speedy-ordering',
    menu: '/profile/menu',
  },
  employeeProfile: '/employee/:id',

  internalServerError: '/500',

  admin: {
    discounts: '/discounts',
    staff: '/staff',
    staffGroups: '/discounts/groups',
    campaign: '/discounts/campaigns',
    discountItem: '/discounts/item/:id?',
    employeeDiscounts: '/discounts/employee-discounts',
    employeeDiscountItem: '/discounts/employee-discounts/item/:id',
    employeeDiscountItemNew: '/discounts/employee-discounts/item',

    menuItem: '/menu-items/item/:id?',
    menuItems: '/menu-items/',
    menu: '/menu/',
    menuLines: '/menu/lines',
    recognition: '/recognition',
    datasets: '/recognition/datasets/',
    dataset: '/recognition/datasets/:id',
    jobs: '/recognition/jobs',
    tasks: '/recognition/tasks',
    task: '/recognition/tasks/task',
    models: '/recognition/models',
    deployments: '/recognition/deployments',
    agents: '/recognition/agents',

    report: '/report',
    revenue: '/report/revenue',
    subsidy: '/report/subsidy',
    storno: '/report/storno',
    item: '/report/item',
    tips: '/report/tips',
    stocklist: '/report/stocklist',

    settings: '/settings',
    settingsPluItem: '/settings/plu/:id',
    settingsPluItemNew: '/settings/plu',
  },
  archiveAdmin: {
    archive: '/archive',
  },
  service: '/online-orders',
  stocklist: '/stocklist',
};
