export interface EmployeeDiscountDto {
  id: string;
  title: string;
  type: EmployeeDiscountType;
  value: number;
  pin: string;
  active: boolean;
}

export enum EmployeeDiscountType {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
}

export interface CreateUpdateEmployeeDiscountItemDto {
  title: string;
  type: EmployeeDiscountType;
  value: number;
  pin: string;
}
