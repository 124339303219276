import React, { Component } from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteChildrenProps } from 'react-router';

import EmployeeDiscountItemStore from 'stores/EmployeeDiscounts/EmployeeDiscountItemStore';
import { EmployeeDiscountItemSteps } from 'stores/EmployeeDiscounts/types';
import RootStore from 'stores/RootStore';
import { ROUTES } from 'routes/routes';

import FormStepper, {
  FormStepperBreadcrumb,
  FormStepperStep,
} from 'components/FormStepper/FormStepper';
import EmployeeDiscountItemStepTitle from './Steps/EmployeeDiscountItemStepTitle';
import EmployeeDiscountItemStepPin from './Steps/EmployeeDiscountItemStepPin';
import EmployeeDiscountItemStepType from './Steps/EmployeeDiscountItemStepType';

type Props = RouteChildrenProps<{
  id: string;
}>;

@observer
class EmployeeDiscountItem extends Component<Props> {
  @observable private _store: EmployeeDiscountItemStore;

  constructor(props: Props) {
    super(props);

    const id = (props.match && props.match.params.id) || '';

    this._store = new EmployeeDiscountItemStore(id);
  }

  async componentDidMount() {
    await this._store.init();
  }

  render() {
    return (
      <FormStepper
        activeStep={this._store.activeStep}
        steps={this._steps}
        breadcrumbs={this._breadcrumbs}
        loading={this._store.loading}
        isNew={!this._store.id}
        isSubmitDisabled={this._isSubmitDisabled}
        onStepBackClick={this._store.handleStepBack}
        onStepNextClick={this._store.handleStepNext}
        onStepChange={this._store.handleStepChange}
        onSubmit={this._store.handleSubmit}
      >
        {this._renderActiveStepForm()}
      </FormStepper>
    );
  }

  private _renderActiveStepForm = () => {
    const { form } = this._store;

    switch (this._store.activeStep) {
      case EmployeeDiscountItemSteps.Title:
        return <EmployeeDiscountItemStepTitle form={form} />;
      case EmployeeDiscountItemSteps.Type:
        return <EmployeeDiscountItemStepType form={form} />;
      case EmployeeDiscountItemSteps.Pin:
        return <EmployeeDiscountItemStepPin form={form} />;
      default:
        return;
    }
  };

  // private _hasStepErrors = (formFields: string[]) => {
  //   const { form } = this._store;
  //   if (!form.validated) return false;

  //   for (const field of formFields) {
  //     if (form.errorFor(field).length) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  private get _isSubmitDisabled() {
    return !this._store.form.isFormValid;
  }

  private get _steps(): FormStepperStep[] {
    const { title, discountValue } = this._store.form;

    return [
      {
        label: 'title.employeeDiscounts.steps.title',
        step: EmployeeDiscountItemSteps.Title,
      },
      {
        label: 'title.employeeDiscounts.steps.type',
        step: EmployeeDiscountItemSteps.Type,
        disabled: !title,
      },
      {
        label: 'title.employeeDiscounts.steps.pin',
        step: EmployeeDiscountItemSteps.Pin,
        disabled: !title || !discountValue,
      },
    ];
  }

  @computed
  private get _breadcrumbs(): FormStepperBreadcrumb[] {
    const { id, isInit, discount } = this._store;

    const breadcrumbs: FormStepperBreadcrumb[] = [
      { to: ROUTES.admin.employeeDiscounts, name: 'title.employeeDiscounts' },
    ];

    if (!id) {
      breadcrumbs.push({
        name: RootStore.localization.formatMessage(
          'title.employeeDiscounts.item',
        ),
      });
    } else {
      breadcrumbs.push({
        name: !isInit ? '...' : discount?.title ?? '',
      });
    }

    return breadcrumbs;
  }
}

export default EmployeeDiscountItem;
