import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function hasOnlySymbols(
  symbols: string[],
  message = 'VM_HAS_ONLY_SYMBOLS',
) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        let isValid = true;

        for (const el of value.toString().split('')) {
          if (!symbols.includes(el)) {
            isValid = false;
          }
        }

        return isValid
          ? undefined
          : {
              message,
              data: {
                name,
                value,
                symbols: symbols.join(', '),
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
