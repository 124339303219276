import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './EmployeeDiscounts.module.scss';
import 'react-table/react-table.css';

import EmployeeDiscountsStore from 'stores/EmployeeDiscounts/EmployeeDiscountsStore';

import Button from 'components/Button/Button';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Icon from 'components/Icon/Icon';
import Table from 'components/Table/Table';

import { config } from './columnsConfig';

@observer
class EmployeeDiscounts extends Component {
  private _store = new EmployeeDiscountsStore();

  async componentDidMount() {
    await this._store.fetchEmployeeDiscounts();
  }

  render() {
    const { searchValue, data, loading } = this._store;

    return (
      <div className={style.content}>
        <div className={style.row}>
          <Button className={style.btn} onClick={this._handleCreateClick}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="button.employeeDiscount.create" />
          </Button>

          <SearchField
            className={style.model__search}
            onChange={this._handleSearch}
            value={searchValue}
          />
        </div>

        <Table
          data={data}
          columns={config({
            onActiveStateUpdate: this._handleActiveStateUpdate,
          })}
          loading={loading}
        />
      </div>
    );
  }

  private _handleCreateClick = () => {
    this._store.createDiscount();
  };

  private _handleActiveStateUpdate = async (id: string, active: boolean) => {
    await this._store.updateActiveState(id, active);
  };

  private _handleSearch = (value: string) => {
    this._store.setSearch(value);
  };
}

export default EmployeeDiscounts;
