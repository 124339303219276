import http from 'http/http';
import { api } from 'http/helpers';
import URLS from 'http/urls';

import {
  CreateUpdateEmployeeDiscountItemDto,
  EmployeeDiscountDto,
} from './types';

export const employeeDiscountsApi = {
  fetchDiscounts() {
    return http.get<EmployeeDiscountDto[]>(api(URLS.finance.employeeDiscounts));
  },

  fetchItemById(id: string) {
    return http.get<EmployeeDiscountDto>(api(URLS.finance.employeeDiscounts), {
      replacePathParams: { id },
    });
  },

  createItem(data: CreateUpdateEmployeeDiscountItemDto) {
    return http.post<CreateUpdateEmployeeDiscountItemDto, EmployeeDiscountDto>(
      api(URLS.finance.employeeDiscounts),
      data,
    );
  },

  updateActiveState(id: string, active: boolean) {
    return http.put<null, EmployeeDiscountDto>(
      api(URLS.finance.employeeDiscountActiveState),
      null,
      {
        replacePathParams: { id, active: active.toString() },
      },
    );
  },
};
