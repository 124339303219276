import React, { FC, useState } from 'react';

import TextField, { Props } from '../Text/TextField';
import Icon from 'components/Icon/Icon';

import styles from './PasswordField.module.scss';

type PasswordFieldProps = Props & {
  showVisibilityButton?: boolean;
};

const PasswordField: FC<PasswordFieldProps> = ({
  showVisibilityButton,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={styles.wrapper}>
      <TextField type={isVisible ? 'text' : 'password'} {...props} />
      {!!showVisibilityButton && (
        <Icon
          className={styles.icon}
          type={isVisible ? 'visibilityOff' : 'visibility'}
          onClick={() => setIsVisible(visible => !visible)}
        />
      )}
    </div>
  );
};

export default PasswordField;
